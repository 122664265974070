





















































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Environment } from '@/models'
import {
  EnvironmentCreateFragment,
  EnvironmentGeneralUpdateFragment
} from './fragments'
import { Field as CheckBox } from '@/components/fields/checkbox'
import Loading from '@/components/Loading.vue'
import Fields from '@/components/form/Fields.vue'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import gql from 'graphql-tag'
import cleanData from '@/utils/gql/cleanData'
import { confirmDelete } from '@/components/dialogs'
import KeyValueList from '@/components/tools/KeyValueList.vue'
import getEnv from '@/plugins/getEnv'

@Component({
  components: {
    Loading,
    Fields,
    CheckBox,
    KeyValueList
  },
  apollo: {
    savedEnvironment: {
      query: gql`
        query ($environmentId: ID) {
          savedEnvironment: environment(environmentId: $environmentId) {
            ...EnvironmentCreate
            ...EnvironmentGeneralUpdate
          }
        }
        ${EnvironmentCreateFragment}
        ${EnvironmentGeneralUpdateFragment}
      `,
      variables() {
        return {
          environmentId: this.environmentId
        }
      }
    }
  }
})
export default class AdminSettingsGeneral extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string

  hasChat = !!getEnv('VUE_APP_CHAT_API_URL')
  desktopApp = !!getEnv('VUE_APP_IS_DESKTOP')

  saving = false

  snackbar = false
  snackbarText = ''
  snackbarColor = ''

  savedEnvironment: Readonly<Environment> | null = null
  environment?: Partial<Environment> = {}

  @Watch('savedEnvironment')
  update(newData: Environment) {
    this.$set(this, 'environment', _cloneDeep(this.savedEnvironment))
  }

  get dirty() {
    return !_isEqual(this.environment, this.savedEnvironment)
  }

  async save() {
    if (!this.environment || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($environmentId: ID, $config: EnvironmentConfigInput) {
            setEnvironmentConfig(
              environmentId: $environmentId
              config: $config
            ) {
              ...EnvironmentCreate
              ...EnvironmentGeneralUpdate
            }
          }
          ${EnvironmentCreateFragment}
          ${EnvironmentGeneralUpdateFragment}
        `,
        // Parameters
        variables: {
          environmentId: this.environment._id,
          config: cleanData(this.environment, EnvironmentGeneralUpdateFragment)
        }
      })

      this.savedEnvironment = result.data.setEnvironmentConfig
      this.snackbar = true
      this.snackbarText = 'Configuración guardada con éxito!'
    } catch (e) {
      this.$emit('error', e)
      this.snackbar = true
      this.snackbarText = 'Error: ' + e.message
      this.snackbarColor = 'error'
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async deleteItem() {
    if (!(await confirmDelete('¿Seguro que quieres eliminar este ambiente?')))
      return
    if (!this.environment || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($environmentId: ID) {
            deleteEnvironment(environmentId: $environmentId)
          }
        `,
        // Parameters
        variables: {
          environmentId: this.environment._id
        }
      })
      this.$emit('delete', result.data.deleteEnvironment)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }

  async downloadConfig() {
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation generateExport($environmentId: ID) {
            environment: generateExport(environmentId: $environmentId)
          }
        `,
        variables: {
          environmentId: this.environmentId
        }
      })

      const data = result.data.environment
      const blob = new Blob([data], { type: 'application/json' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')

      a.href = url
      a.download = `${this.environmentId}.sodlabx`
      document.body.appendChild(a)
      a.click()

      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    } catch (e) {
      this.$emit('error', e)
      await this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: 'Error: ' + e
      })
    } finally {
      this.saving = false
    }
  }

  uploadInvoke(type: string) {
    const uploadInput = document.getElementById(
      `upload-${type}`
    ) as HTMLInputElement
    if (!uploadInput) return false

    uploadInput.addEventListener('change', this.uploadConfig)
    uploadInput.click()
  }

  async uploadConfig(event: Event) {
    const target = event.target as HTMLInputElement
    if (!target) return false
    target.removeEventListener('change', this.uploadConfig)

    if (!target.files || !target.files[0]) return false

    const keepIDs =
      target.id === 'upload-keepids' || target.id === 'upload-deriveids'
    const deriveIDs = target.id === 'upload-deriveids'

    this.saving = true
    try {
      const data = await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event: Event) => {
          const target = event.target as FileReader
          const content = target.result as string
          if (!target || !content) reject()

          resolve(content)
        }
        reader.readAsText(target.files![0])
      })

      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation importEnvironment(
            $environmentId: ID
            $data: String
            $keepIDs: Boolean
            $deriveIDs: Boolean
          ) {
            importEnvironment(
              environmentId: $environmentId
              data: $data
              keepIDs: $keepIDs
              deriveIDs: $deriveIDs
            ) {
              _id
              name
            }
          }
        `,
        variables: {
          environmentId: this.environmentId,
          data,
          keepIDs,
          deriveIDs
        }
      })

      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Ambiente actualizado con éxito!'
      })
    } catch (e) {
      this.$emit('error', e)
      await this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: 'Error: ' + e
      })
    } finally {
      this.saving = false
    }
  }
}
